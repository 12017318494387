:root[theme="dusk"] {
  --color-element-active-color: rgba(255, 255, 255, 1);
  --color-element-neutral-color: rgba(255, 255, 255, 0.5);
  --color-element-disabled-color: rgba(255, 255, 255, 0.2);
  --color-element-active-inverted-color: rgba(0, 0, 0, 1);
  --color-element-neutral-inverted-color: rgba(0, 0, 0, 0.6);
  --color-element-disabled-inverted-color: rgba(0, 0, 0, 0.3);
  --color-container-backdrop-color: rgba(51, 51, 51, 1);
  --color-container-section-color: rgba(59, 59, 59, 1);
  --color-container-background-color: rgba(64, 64, 64, 1);
  --color-container-global-color: rgba(77, 77, 77, 1);
  --color-container-overlay-color: rgba(0, 0, 0, 0.2);
  --color-border-divider-color: rgba(255, 255, 255, 0.12);
  --color-border-outline-color: rgba(0, 0, 0, 0.4);
  --color-border-solid-color: rgba(255, 255, 255, 1);
  --color-instrument-dynamic-color: rgba(51, 187, 255, 1);
  --color-instrument-trend-color: rgba(51, 187, 255, 0.3);
  --color-instrument-static-color: rgba(255, 255, 255, 1);
  --color-instrument-input-color: rgba(134, 198, 0, 1);
  --color-instrument-port-color: rgba(199, 81, 124, 1);
  --color-instrument-starboard-color: rgba(31, 137, 112, 1);
  --color-instrument-frame-color: rgba(56, 56, 56, 1);
  --color-instrument-track-color: rgba(89, 89, 89, 1);
  --color-instrument-tick-mark-primary-color: rgba(255, 255, 255, 1);
  --color-instrument-tick-mark-secondary-color: rgba(153, 153, 153, 1);
  --color-instrument-tick-mark-tertiary-color: rgba(77, 77, 77, 1);
  --color-alert-running-color: rgba(0, 160, 0, 1);
  --color-alert-caution-color: rgba(255, 229, 0, 1);
  --color-alert-warning-color: rgba(251, 138, 0, 1);
  --color-alert-alarm-color: rgba(255, 0, 0, 1);
  --color-alert-critical-alarm-color: rgba(254, 0, 255, 1);
  --color-alert-high-color: rgba(0, 0, 0, 0.2);
  --color-alert-low-color: rgba(0, 0, 0, 0.2);
  --color-automation-acids-alkalis: rgba(133, 95, 145, 1);
  --color-automation-air-ventilation: rgba(180, 180, 180, 1);
  --color-automation-fire-fighting: rgba(119, 90, 90, 1);
  --color-automation-flammable-gas: rgba(113, 112, 65, 1);
  --color-automation-fresh-water: rgba(97, 117, 134, 1);
  --color-automation-fuel: rgba(135, 111, 91, 1);
  --color-automation-masses: rgba(124, 71, 60, 1);
  --color-automation-non-flamable-gas: rgba(97, 97, 97, 1);
  --color-automation-oil: rgba(145, 101, 56, 1);
  --color-automation-sea-water: rgba(88, 121, 85, 1);
  --color-automation-steam: rgba(122, 122, 122, 1);
  --color-automation-waste-media: rgba(43, 43, 43, 1);
  --color-normal-enabled-background-color: rgba(89, 89, 89, 1);
  --color-normal-enabled-border-color: rgba(255, 255, 255, 0.05);
  --color-normal-hover-background-color: rgba(102, 102, 102, 1);
  --color-normal-hover-border-color: rgba(255, 255, 255, 0.05);
  --color-normal-pressed-background-color: rgba(128, 128, 128, 1);
  --color-normal-pressed-border-color: rgba(255, 255, 255, 0.2);
  --color-normal-focused-background-color: rgba(89, 89, 89, 1);
  --color-normal-focused-border-color: rgba(255, 255, 255, 1);
  --color-normal-disabled-background-color: rgba(255, 255, 255, 0);
  --color-normal-disabled-border-color: rgba(255, 255, 255, 0.1);
  --color-on-normal-active-color: rgba(255, 255, 255, 1);
  --color-on-normal-neutral-color: rgba(255, 255, 255, 0.5);
  --color-on-normal-disabled-color: rgba(255, 255, 255, 0.2);
  --color-raised-enabled-background-color: rgba(255, 255, 255, 0.3);
  --color-raised-enabled-border-color: rgba(255, 255, 255, 0.05);
  --color-raised-hover-background-color: rgba(255, 255, 255, 0.4);
  --color-raised-hover-border-color: rgba(255, 255, 255, 0.05);
  --color-raised-pressed-background-color: rgba(255, 255, 255, 0.5);
  --color-raised-pressed-border-color: rgba(255, 255, 255, 0.2);
  --color-raised-focused-background-color: rgba(255, 255, 255, 0.4);
  --color-raised-focused-border-color: rgba(255, 255, 255, 1);
  --color-raised-disabled-background-color: rgba(255, 255, 255, 0);
  --color-raised-disabled-border-color: rgba(255, 255, 255, 0.1);
  --color-on-raised-active-color: rgba(255, 255, 255, 1);
  --color-on-raised-neutral-color: rgba(255, 255, 255, 0.5);
  --color-on-raised-disabled-color: rgba(255, 255, 255, 0.2);
  --color-indent-enabled-background-color: rgba(0, 0, 0, 0.2);
  --color-indent-enabled-border-color: rgba(0, 0, 0, 0);
  --color-indent-hover-background-color: rgba(0, 0, 0, 0.4);
  --color-indent-hover-border-color: rgba(0, 0, 0, 0);
  --color-indent-pressed-background-color: rgba(0, 0, 0, 0.6);
  --color-indent-pressed-border-color: rgba(0, 0, 0, 0.6);
  --color-indent-focused-background-color: rgba(0, 0, 0, 0.2);
  --color-indent-focused-border-color: rgba(255, 255, 255, 1);
  --color-indent-disabled-background-color: rgba(0, 0, 0, 0);
  --color-indent-disabled-border-color: rgba(255, 255, 255, 0.1);
  --color-on-indent-active-color: rgba(255, 255, 255, 1);
  --color-on-indent-neutral-color: rgba(255, 255, 255, 0.5);
  --color-flat-enabled-background-color: rgba(255, 255, 255, 0);
  --color-flat-enabled-border-color: rgba(255, 255, 255, 0);
  --color-flat-hover-background-color: rgba(255, 255, 255, 0.1);
  --color-flat-hover-border-color: rgba(255, 255, 255, 0);
  --color-flat-pressed-background-color: rgba(255, 255, 255, 0.2);
  --color-flat-pressed-border-color: rgba(255, 255, 255, 0);
  --color-flat-focused-background-color: rgba(0, 0, 0, 0);
  --color-flat-focused-border-color: rgba(255, 255, 255, 1);
  --color-flat-disabled-background-color: rgba(0, 0, 0, 0);
  --color-flat-disabled-border-color: rgba(255, 255, 255, 0);
  --color-on-flat-active-color: rgba(255, 255, 255, 1);
  --color-on-flat-neutral-color: rgba(255, 255, 255, 0.5);
  --color-on-flat-disabled-color: rgba(255, 255, 255, 0.2);
  --color-selected-enabled-background-color: rgba(204, 204, 204, 1);
  --color-selected-enabled-border-color: rgba(255, 255, 255, 0);
  --color-selected-hover-background-color: rgba(179, 179, 179, 1);
  --color-selected-hover-border-color: rgba(255, 255, 255, 0);
  --color-selected-pressed-background-color: rgba(153, 153, 153, 1);
  --color-selected-pressed-border-color: rgba(255, 255, 255, 0.2);
  --color-selected-focused-background-color: rgba(204, 204, 204, 1);
  --color-selected-focused-border-color: rgba(255, 255, 255, 1);
  --color-selected-disabled-background-color: rgba(255, 255, 255, 0.1);
  --color-selected-disabled-border-color: rgba(255, 255, 255, 0);
  --color-on-selected-active-color: rgba(0, 0, 0, 1);
  --color-on-selected-neutral-color: rgba(0, 0, 0, 0.7);
  --color-on-selected-disabled-color: rgba(255, 255, 255, 0.2);
  --color-thumb-enabled-background-color: rgba(255, 255, 255, 1);
  --color-thumb-enabled-border-color: rgba(0, 0, 0, 0.6);
  --color-thumb-hover-background-color: rgba(204, 204, 204, 1);
  --color-thumb-hover-border-color: rgba(0, 0, 0, 0.6);
  --color-thumb-pressed-background-color: rgba(153, 153, 153, 1);
  --color-thumb-pressed-border-color: rgba(0, 0, 0, 0.6);
  --color-thumb-focused-background-color: rgba(255, 255, 255, 1);
  --color-thumb-focused-border-color: rgba(255, 255, 255, 1);
  --color-thumb-disabled-background-color: rgba(0, 0, 0, 0.3);
  --color-thumb-disabled-border-color: rgba(0, 0, 0, 0);
  --color-on-thumb-active-color: rgba(0, 0, 0, 1);
  --color-on-thumb-neutral-color: rgba(0, 0, 0, 0.7);
  --color-on-thumb-disabled-color: rgba(255, 255, 255, 0.2);
  --color-amplified-enabled-background-color: rgba(89, 89, 89, 1);
  --color-amplified-enabled-border-color: rgba(0, 0, 0, 0.4);
  --color-amplified-hover-background-color: rgba(102, 102, 102, 1);
  --color-amplified-hover-border-color: rgba(0, 0, 0, 0.4);
  --color-amplified-pressed-background-color: rgba(128, 128, 128, 1);
  --color-amplified-pressed-border-color: rgba(255, 255, 255, 0.2);
  --color-amplified-focused-background-color: rgba(89, 89, 89, 1);
  --color-amplified-focused-border-color: rgba(255, 255, 255, 1);
  --color-amplified-disabled-background-color: rgba(255, 255, 255, 0.1);
  --color-amplified-disabled-border-color: rgba(255, 255, 255, 0);
  --color-on-amplified-active-color: rgba(255, 255, 255, 1);
  --color-on-amplified-neutral-color: rgba(255, 255, 255, 0.5);
  --color-on-amplified-disabled-color: rgba(255, 255, 255, 0.2);
  --color-running-enabled-background-color: rgba(0, 153, 0, 1);
  --color-running-enabled-border-color: rgba(0, 0, 0, 0.1);
  --color-running-hover-background-color: rgba(0, 128, 0, 1);
  --color-running-hover-border-color: rgba(0, 0, 0, 0.1);
  --color-running-pressed-background-color: rgba(0, 102, 0, 1);
  --color-running-pressed-border-color: rgba(0, 0, 0, 0.3);
  --color-running-focused-background-color: rgba(0, 153, 0, 1);
  --color-running-focused-border-color: rgba(255, 255, 255, 1);
  --color-running-disabled-background-color: rgba(0, 153, 0, 1);
  --color-running-disabled-border-color: rgba(0, 0, 0, 0);
  --color-on-running-active-color: rgba(0, 0, 0, 1);
  --color-on-running-neutral-color: rgba(0, 0, 0, 0.7);
  --color-on-running-disabled-color: rgba(0, 0, 0, 0.3);
  --color-caution-enabled-background-color: rgba(255, 213, 0, 1);
  --color-caution-enabled-border-color: rgba(0, 0, 0, 0.1);
  --color-caution-hover-background-color: rgba(204, 170, 0, 1);
  --color-caution-hover-border-color: rgba(0, 0, 0, 0.2);
  --color-caution-pressed-background-color: rgba(153, 128, 0, 1);
  --color-caution-pressed-border-color: rgba(0, 0, 0, 0.3);
  --color-caution-focused-background-color: rgba(255, 213, 0, 1);
  --color-caution-focused-border-color: rgba(255, 255, 255, 1);
  --color-caution-disabled-background-color: rgba(255, 213, 0, 1);
  --color-caution-disabled-border-color: rgba(0, 0, 0, 0);
  --color-on-caution-active-color: rgba(0, 0, 0, 1);
  --color-on-caution-neutral-color: rgba(0, 0, 0, 0.7);
  --color-on-caution-disabled-color: rgba(0, 0, 0, 0.3);
  --color-warning-enabled-background-color: rgba(255, 140, 0, 1);
  --color-warning-enabled-border-color: rgba(0, 0, 0, 0.1);
  --color-warning-hover-background-color: rgba(204, 112, 0, 1);
  --color-warning-hover-border-color: rgba(0, 0, 0, 0.2);
  --color-warning-pressed-background-color: rgba(153, 84, 0, 1);
  --color-warning-pressed-border-color: rgba(0, 0, 0, 0.4);
  --color-warning-focused-background-color: rgba(255, 140, 0, 1);
  --color-warning-focused-border-color: rgba(255, 255, 255, 1);
  --color-warning-disabled-background-color: rgba(255, 140, 0, 1);
  --color-warning-disabled-border-color: rgba(0, 0, 0, 0);
  --color-on-warning-active-color: rgba(0, 0, 0, 1);
  --color-on-warning-neutral-color: rgba(0, 0, 0, 0.7);
  --color-on-warning-disabled-color: rgba(0, 0, 0, 0.3);
  --color-alarm-enabled-background-color: rgba(204, 0, 0, 1);
  --color-alarm-enabled-border-color: rgba(0, 0, 0, 0.1);
  --color-alarm-hover-background-color: rgba(153, 0, 0, 1);
  --color-alarm-hover-border-color: rgba(0, 0, 0, 0.2);
  --color-alarm-pressed-background-color: rgba(102, 0, 0, 1);
  --color-alarm-pressed-border-color: rgba(0, 0, 0, 0.4);
  --color-alarm-focused-background-color: rgba(204, 0, 0, 1);
  --color-alarm-focused-border-color: rgba(255, 255, 255, 1);
  --color-alarm-disabled-background-color: rgba(204, 0, 0, 1);
  --color-alarm-disabled-border-color: rgba(0, 0, 0, 0);
  --color-on-alarm-active-color: rgba(255, 255, 255, 1);
  --color-on-alarm-neutral-color: rgba(255, 255, 255, 0.7);
  --color-on-alarm-disabled-color: rgba(255, 255, 255, 0.3);
  --color-critical-alarm-enabled-background-color: rgba(254, 0, 255, 1);
  --color-critical-alarm-enabled-border-color: rgba(0, 0, 0, 0.1);
  --color-critical-alarm-hover-background-color: rgba(203, 0, 204, 1);
  --color-critical-alarm-hover-border-color: rgba(0, 0, 0, 0.2);
  --color-critical-alarm-pressed-background-color: rgba(152, 0, 153, 1);
  --color-critical-alarm-pressed-border-color: rgba(0, 0, 0, 0.4);
  --color-critical-alarm-focused-background-color: rgba(254, 0, 255, 1);
  --color-critical-alarm-focused-border-color: rgba(255, 255, 255, 1);
  --color-critical-alarm-disabled-background-color: rgba(254, 0, 255, 1);
  --color-critical-alarm-disabled-border-color: rgba(0, 0, 0, 0);
  --color-on-critical-alarm-active-color: rgba(0, 0, 0, 1);
  --color-on-critical-alarm-neutral-color: rgba(0, 0, 0, 0.7);
  --color-on-critical-alarm-disabled-color: rgba(0, 0, 0, 0.3);
}
