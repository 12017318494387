/*
// 22/03/26:
// exported Figma Panel Night 3.0 using CSSGen Plugin
// use REM was selected
*/

/*
ob_day.css has more variables than the others as of 2022/03/26
need to add equivalent variables to the other css files
 */
html {
  font-size: 16px;
}

.text-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.text-style-ui-title {
  font-size: 1.5rem;
  font-family: "Open Sans";
  font-weight: bolder;
  font-style: normal;
  line-height: 32px;
  text-decoration: none;
}

.text-style-ui-subtitle {
  font-size: 1.5rem;
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  line-height: 32px;
  text-decoration: none;
}

.text-style-ui-overline {
  font-size: 0.75rem;
  font-family: "Open Sans";
  font-weight: bolder;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 1.12px;
  text-decoration: none;
}

.text-style-ui-button {
  font-size: 1rem;
  font-family: "Open Sans";
  font-weight: bolder;
  font-style: normal;
  line-height: 24px;
  text-decoration: none;
}

.text-style-ui-body-active {
  font-size: 1rem;
  font-family: "Open Sans";
  font-weight: bolder;
  font-style: normal;
  line-height: 24px;
  text-decoration: none;
  color: var(--color-element-active-color);
}

.text-style-ui-body {
  font-size: 1rem;
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  line-height: 24px;
  text-decoration: none;
  color: var(--color-element-active-color);
}

.text-style-ui-label-active {
  font-size: 0.75rem;
  font-family: "Open Sans";
  font-weight: bolder;
  font-style: normal;
  text-decoration: none;
}

.text-style-ui-label {
  font-size: 0.75rem;
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

.text-style-instrument-label-s {
  font-size: 1rem;
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

.text-style-instrument-label-m {
  font-size: 1.5rem;
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  /* line-height: 42px; */
  text-decoration: none;
}

.text-style-instrument-label-l {
  font-size: 2rem;
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  /* line-height: 64px; */
  text-decoration: none;
}

.text-style-instrument-value-s {
  font-size: 1rem;
  font-family: "Open Sans";
  font-weight: bolder;
  font-style: normal;
  text-decoration: none;
}

.text-style-instrument-value-s-enhanced {
  font-size: 1.5rem;
  font-family: "Open Sans";
  font-weight: bolder;
  font-style: normal;
  text-decoration: none;
}

.text-style-instrument-value-m {
  font-size: 2rem;
  font-family: "Open Sans";
  font-weight: bolder;
  font-style: normal;
  /* line-height: 48px; */
  line-height: 2rem;
  text-decoration: none;
}

.text-style-instrument-value-m-enhanced {
  font-size: 3rem;
  font-family: "Open Sans";
  font-weight: bolder;
  font-style: normal;
  /* line-height: 64px; */
  line-height: 3rem;
  text-decoration: none;
}

.text-style-instrument-value-l {
  font-size: 4rem;
  font-family: "Open Sans";
  font-weight: bolder;
  font-style: normal;
  /* line-height: 88px; */
  line-height: 4rem;
  text-decoration: none;
}

.text-style-instrument-value-l-enhanced {
  font-size: 5rem;
  font-family: "Open Sans";
  font-weight: bolder;
  font-style: normal;
  /* line-height: 104px; */
  line-height: 5rem;
  text-decoration: none;
}

.text-style-instrument-unit-s {
  font-size: 1rem;
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

.text-style-instrument-unit-m {
  font-size: 1.5rem;
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  /* line-height: 42px; */
  text-decoration: none;
}

.text-style-instrument-unit-l {
  font-size: 2rem;
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  /* line-height: 64px; */
  text-decoration: none;
}

.text-style-instrument-status-s {
  font-size: 1rem;
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

.text-style-instrument-status-m {
  font-size: 1.5rem;
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  line-height: 42px;
  text-decoration: none;
}

.text-style-instrument-status-l {
  font-size: 2rem;
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  line-height: 64px;
  text-decoration: none;
}

.effect-style-shadow-flat {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}

.effect-style-shadow-raised {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.effect-style-shadow-floating {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.3);
}

.effect-style-shadow-overlay {
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.4);
}

.instrument-label {
  color: var(--color-element-neutral-color);
}
.instrument-dynamic {
  color: var(--color-instrument-dynamic-color);
}
.instrument-static {
  color: var(--color-instrument-static-color);
}
.instrument-input {
  color: var(--color-instrument-input-color);
}
.instrument-unit {
  color: var(--color-element-neutral-color);
}
.instrument-status {
  color: var(--color-element-neutral-color);
}