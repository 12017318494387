@import "https://js.arcgis.com/4.22/@arcgis/core/assets/esri/themes/light/main.css";

.maritime-chart {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.map-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 8px;
  border-radius: 8px;
}

.map-div {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
